/* Global Colors */
:root { //chart color
  --primary-color: #233d4d;
  --secondary-color: #8AC74D;
  --background-color: #F6F7FC;
  --dark-font-color: #233D4D;
  --grey-font-color: #8898aa;
  --error-color: #fb6340;
}

//:root { //Brown theme
//  --primary-color: #617184;
//  --secondary-color: #8B8C94;
//  --dark-font-color: #A47F59;
//  --grey-font-color: #cbc9b7;
//  --error-color: #885E4E;
//  --background-color: #cbc9b7;
//}
$light-font-color: #ffffff;
$darker-white: #f5f7f9;
$eless-light-gray: #E9ECEF;
$green-check: #2DCE89;
$disabled-color: rgb(170, 170, 170);
$grey-box-background: #F6F9FC;
$standard-blue: #1e90ff;
$focused-input-border: #8898aa;

.item-renderer input[type="checkbox"]:checked {
  background-color: green!important;
  color: green!important;
  &:before {
    width: 10px;
    height: 10px;
    background-color: green;
  }
}
/* Font Family Quicksand */
@font-face {
  font-family: RegularFont;
  src: url("../fonts/quicksand/Quicksand-Regular.ttf");
}
@font-face {
  font-family: SemiBoldFont;
  src: url("../fonts/quicksand/Quicksand-SemiBold.ttf");
}
@font-face {
  font-family: BoldFont;
  src: url("../fonts/quicksand/Quicksand-Bold.ttf");
}
@font-face {
  font-family: XBoldFont;
  src: url("../fonts/quicksand/Quicksand-Bold.ttf");
}
body {
  padding-right: 0!important;
}
.auth-nav .navbar-brand {
  max-width: 250px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  input, input::-webkit-input-placeholder {
    font-family: RegularFont, serif!important;
  }
  select {
    font-family: RegularFont, serif!important;
  }
  body {
    font-family: RegularFont, serif!important;
  }
  .custom-alert-title {
    font-family: RegularFont, serif!important;
  }
  .nav-tabs {
    font-family: RegularFont, serif;
    .nav-item {
      .active {
        font-family: SemiBoldFont, serif;
      }
    }
  }
}
.secondary-text-color {
  color: var(--secondary-color);
}
.btn {
  font-weight: normal!important;
  font-family: SemiBoldFont, sans-serif!important;
}
.border-radius-none {
  border-radius: 0;
}
.table td {
  font-size: 16px!important;
}
.scrollbar-container .nav-link {
  font-size: 16px!important;
  color: var(--dark-font-color)!important;
}
/* Global Components */
h1, h2, h3, h4, h5, h6 {
  font-family: XBoldFont, sans-serif!important;
  color: var(--dark-font-color);
  font-weight: normal!important;
}
h5{
  text-transform: uppercase;
  color: var(--grey-font-color);
  font-size: 16px;
}
h5.modal-title {
  text-transform: unset!important;
}
.text-muted {
  color: var(--grey-font-color)!important;
}
.bold-font {
  font-family: XBoldFont, sans-serif!important;
  font-weight: normal!important;
}
.dropdown-divider {
  border-width: 3px;
  margin: 5px 0;
}
.dropdown-menu .fas {
  font-size: 16px!important;
}
.dropdown-menu {
  &.dropdown-menu-right {
    top: 6px!important;
  }
  &.email-dropdown-menu {
    top: -28px !important;
  }
}
.background-transparent {
  background-color: transparent!important;
}
.background-default {
  background-color: var(--background-color);
}
.background-white {
  background-color: #fff;
}
.background-primary {
  background-color: var(--primary-color);
}
.footer {
  background-color: var(--primary-color);
  padding: 40px 2rem;
  .container {
    padding-left: 45px!important;
  }
}
img {
  max-width: 100%;
}
.gray-title {
  text-transform: uppercase;
  color: var(--grey-font-color);
  font-size: 14.5px;
}
input[type='color'] {
  width: 90%;
  padding: 10px;
  height: 50px;
}
.overflow-auto {
  overflow: auto;
}
.input-height {
  height: 46px!important;
}
input, input::-webkit-input-placeholder {
  font-family: RegularFont, sans-serif!important;
  color: var(--dark-font-color);
}
.vertical-top-wrapper {
  display : flex;
  justify-content: flex-start;
  height: 100%;
  flex-direction: column;
}
.table td {
  font-size: 0.925rem;
}
.margin-bottom-80 {
  margin-bottom: 80px;
}
.custom-card-distance {
  margin-bottom: 40px;
}
h2 {
  font-size: 20px;
  margin-bottom: 5px;
}
h2.light {
  color: $light-font-color;
}
h2.dark {
  color: var(--dark-font-color);
}
h3{
  font-size: 18px;
  color: var(--dark-font-color);
}
h4 {
  font-size: 16px;
  margin-bottom: 0;
  font-family: XBoldFont, sans-serif!important;
}
.fontsize-100 {
  font-size: 100%!important;
}
.input-handler-wrapper {
  position: relative;
}
.h2-description {
  font-size: 16px;
}
.font-s-14 {
  font-size: 14px;
}
.disabled-color {
  color: $disabled-color!important;
  background-color: #e9ecef;
  cursor: default!important;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
body {
  background-color: var(--background-color);
  color: var(--dark-font-color);
  font-family: RegularFont, sans-serif;
  font-size: 16px;
}
.custom-control-label {
  font-size: 16px!important;
}
.select-search__input {
  font-size: 16px!important;
}
input {
  font-size: 16px!important;
}
.form-control {
  font-size: 16px;
}
b {
  font-family: XBoldFont, sans-serif;
  font-weight: normal!important;
}
a {
  color: var(--primary-color);
  &:hover {
    color: var(--primary-color);
  }
}
.w-20 {
  width: 20%!important;
}
.w-19 {
  width: 19%!important;
}
.border-3 {
  border-width: 3px!important;
}
.loading-ani-toggler {
  top: 0;
}
.turned-icon {
  transform: scaleX(-1);
}

.hover:hover {
  background-color: lightgray;
}

.static-warning-notification {
  background-color: #FFDBBE;
  padding: 8px 16px 10px;
  border-radius: 4px;
  margin-top: 15px;
  &.grey {
    background-color: $eless-light-gray!important;
  }
  &.more-padding {
    padding: 16px 26px;
  }
}
.form-group {
  margin-bottom: 1.65rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
@media screen and (min-width: 992px){
  .lg-text-left{
    text-align: left!important;
  }
}
@media screen and (max-width: 420px){
  .form-group {
    padding: 0!important;
  }
}
.form-control, .form-control:active {
  color: var(--dark-font-color)!important;
}
.form-control:disabled {
  color: $disabled-color!important;
}
.bg-color-transparent {
  background-color: transparent;
}
.form-control:focus {
  border-color: var(--dark-font-color);
}
.form-font-size {
  font-size: 0.875rem;
}
.max-width-inherit {
  max-width: inherit!important;
}
.eless-bg-gradient {
  background: linear-gradient(87deg, #ffffff 0, $eless-light-gray 100%) !important;
}
.sweet-alert.second {
  top: 26%!important;
}
.secondary-font-color {
  color: var(--grey-font-color);
}
.eless-color {
  color: var(--dark-font-color)!important;
}
.white-color {
  color: $light-font-color!important;
}
.eless-fill {
  fill: var(--primary-color);
}
.disable-max-w {
  max-width: inherit!important;
}
.eless-bg {
  background: var(--primary-color);
}
.form-field-height {
  height: 46px!important;
}
.eless-btn {
  height: 31px;
  padding: 0 30px;
}
.width-auto {
  width: auto!important;
}

#support_description {
  height: 300px;
}


@media screen and (max-width: 562px){
  .custom-resp-margin {
    margin-bottom: .5rem;
    margin-right: 0!important;
  }
}
@media screen and (max-width: 399px){
  .eless-btn {
    width: 100%;
    margin-left: 0!important;
    margin-right: 0!important;
  }
}
.ni-curved-next {
  transform: rotate(180deg);
  font-size: 14px;
  margin-right: 4px;
}
.eless-text-orange {
  color: var(--secondary-color);
  &.link:hover {
    color: var(--secondary-color);
  }
}
.error-background-color {
  background-color: var(--error-color);
  border-color: var(--error-color);
}
.eless-border-orange {
  border-color: var(--secondary-color)!important;
}
.eless-font-bold {
  font-family: XBoldFont, sans-serif!important;
}
.eless-font-semi-bold {
  font-family: SemiBoldFont, sans-serif!important;
}
hr{
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
.cursor-pointer {
  cursor: pointer;
}
.max-text-width {
  max-width: 900px;
  margin: 0 auto;
}
.bg-info {
  background-color: var(--primary-color)!important;
}
.bg-eless-orange {
  background-color: #fe821e;
}
.table-title {
  font-size: 18px;
}
.main-card {
  border-radius: 6px;
  color: $light-font-color;
}
.height-50 {
  height: 50px!important;
}
.light-cards-row {
  .card {
    background-color: $grey-box-background;
    height: 100%;
    margin-bottom: 15px;
    box-shadow: none;
  }
  .card-header {
    background-color: $grey-box-background;
    text-transform: uppercase;
    border: none;
    padding-bottom: 8px;
  }
  .card-body{
    padding-top: 0;
    padding-bottom: 0;
    font-size: 15px;
    line-height: 2.2;
  }
}
.cursor-default {
  cursor: default;
}
.sortable {
  cursor: pointer;
}
.invisible-cards-row {
  .card {
    background: transparent;
    height: 100%;
    margin-bottom: 15px;
    box-shadow: none;
  }
  .card-header {
    background-color: transparent;
    text-transform: uppercase;
    border: none;
  }
  .card-body{
    padding-top: 0;
    font-size: 15px;
  }
}
.cards-separator-right {
  border-right: 1px solid #707070;
}
@media screen and (max-width: 857px){
  .cards-separator-right {
    border-right: none;
  }
}
ul {
  &.checklist {
    padding-left: 15px!important;
    padding-top: 15px;
    li {
      display: block;
      padding: 10px 0;
      span {
        display: inline-block;
        max-width: 390px;
        font-family: Open Sans, sans-serif!important;
        font-size: 1rem!important;
      }
      &::before {
        color: $green-check!important;
        padding-right: 20px;
      }
    }
  }
}
select:not(disabled) {
  cursor: pointer;
}
/* COMPONENTS */
/* Accept Changes Footer */
.accept-changes-footer {
  padding: 20px 15px;
  background-color: rgba(253,214,142,0.59);
  border-radius: 10px;
}
.changes-detected {
  position: relative;
  text-decoration: none;
  cursor: pointer;
  min-height: 24px;
  width: auto;
  &:before {
    content: '!';
    background-color: rgba(255, 180, 48, 0.59);
    color: #fff;
    position: absolute;
    left: -28px;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    font-weight: bold;
    text-align: center;
    font-family: 'Open Sans', serif;
  }
  &:after {
    content: attr(data-tooltip);
    position: absolute;
    top: -8px;
    left: 0;
    background: #ffcb66;
    padding: 5px 15px;
    color: black;
    -webkit-border-radius: 10px;
    -moz-border-radius   : 10px;
    border-radius        : 10px;
    white-space: nowrap;
    opacity: 0;
    /* At time of this creation, only Fx4 doing pseduo transitions */
    -webkit-transition: all 0.2s ease;
    -moz-transition   : all 0.2s ease;
  }
  //&:hover:after {
  //  bottom: 100%;
  //}
  &:hover:after {
    opacity: 1;
  }
}
.changes-detected-download {
  background-color: rgba(255, 180, 48, 0.59);
  color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  font-weight: bold;
  text-align: center;
  font-family: 'Open Sans', serif;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
}
.changeable-data-download {
  position: relative;
  .changes-detected-download {
    position: absolute;
    left: -28px;
  }
}
@media screen and (min-width: 768px){
  .accept-changes-footer {
    .buttons-wrapper {
      text-align: right;
    }
  }
}
@media screen and (max-width: 767px){
  .accept-changes-footer {
    .text-wrapper {
      margin-bottom: 25px;
      line-height: 1;
    }
    .buttons-wrapper {
      text-align: center;
      .eless-btn {
        margin-bottom: 10px;
        width: 100%;
        margin-right: 0!important;
      }
    }
  }
}
//@media screen and (max-width: 991px){
//  .accept-changes-footer {
//    .buttons-wrapper {
//      text-align: center;
//        margin-bottom: 10px;
//    }
//  }
//}
/* End Accept Changes Footer */
/* List Dotted */
.list-middot {
  list-style: none;
  padding: 0;
}

.list-middot > li {
  display: inline;
}
.list-middot > li:not(:last-child)::after {
  content: "\00b7";
  margin: 0 15px;
  font-size: 20px;
  font-weight: bold;
}
/* End List Dotted */
/* Info Box */
.info-box {
  position: absolute;
  border-radius: 5px;
  background: $eless-light-gray;
  z-index: 1;
  max-width: 250px;
  font-size: 12px;
  color: var(--dark-font-color);
}
.info-box-header {
  padding: 10px 20px;
  border-bottom: 1px solid #fff;
  text-align: center;
}
.info-box-body {
  padding: 10px 20px;
}
.info-box-body-item {
  margin-bottom: 4px;
}
.info-box-separator {
  border-bottom: 1.5px solid var(--dark-font-color);
}
@media screen and (min-width: 941px) {
  .payment-method-info {
  }
}
/* InfoBox */
/* Buttons */
.btn-group .active{
  background-color: #E9ECEF!important;
}
/* End Buttons */
/* HeaderInfoCard */
.info-card {
  .icon-wrapper {
    display: inline-block;
    background-color: $eless-light-gray;
    padding: 10px 10px 5px;
    border-radius: 25px;
    i {
      font-size: 36px;
    }
  }

  .growth-info {
    color: var(--grey-font-color);
    b {
      &.plus {
        color: $green-check!important;
      }
      &.minus {
        color: var(--error-color)!important;
      }
    }
  }
}
.info-card-title {
  font-size: 20px;
}
.current-count {
  font-size: 24px;
  color: var(--secondary-color);
  font-family: XBoldFont, sans-serif;
  line-height: 1;
}
.growth {
  &.minus {

  }
  &.plus {

  }
}
/* Custom Alert */
.custom-alert {
  .modal-content {
    padding: 3% 10%;
    text-align: center;
    .btn-modal {
      margin-bottom: 12px!important;
    }
  }
}
.modal-text-addition {
  font-style: italic;
  font-size: 14px;
}
.custom-alert-title {
  font-size: 28px;
  font-family: RegularFont, sans-serif;
  margin-bottom: 15px;
}
.swal2-icon {
  border-color: #B3B7C1!important;
  color: var(--primary-color)!important;
  .swal2-success-ring {
    border-color: #B3B7C1!important;
  }
  .swal2-success-line-long {
    background-color:  var(--primary-color)!important;
  }
  .swal2-success-line-tip {
    background-color: var(--primary-color)!important;
  }
}
.swal2-icon.swal2-warning {
   border-color: var(--secondary-color)!important;
   color: var(--secondary-color)!important;
}
/* End Custom Alert */
/* Offer deadline */
.offer-deadline {
  position: absolute;
  bottom: -14px;
  left: 5%;
  background-color: #FFE8D5;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;
  z-index: 1;
}
.lead {
  color: var(--primary-color)!important;
  font-size: 16px;
  text-align: left;
  line-height: 1.2!important;
  margin: 20px 0 30px 0;
}
.sweet-alert {
  .btn-warning {
    width: 100%;
  }
}
@media screen and (min-width: 650px){
  .sweet-alert {
    top: 60% !important;
    width: 600px !important;
    padding: 17px 95px!important;
  }
  .higher-placed-modal {
    top: 40% !important;
  }
}
  /* End Offer deadline */
/* Input Slider */
.noUi-connect {
  background: var(--secondary-color)!important;
}
.noUi-handle {
  background-color: var(--secondary-color)!important;
  &.noUi-active {
    box-shadow: 0 0 0 2px var(--secondary-color)!important;
  }
}
.range-slider-value {
  padding-bottom: 7px;
  border-radius: 6px;
  width: 220px;
  display: block;
}
.input-slider {
  margin-bottom: 10px;
  z-index: 0;
}
/* End Input Slider */
/* Custom Rows Table */
.custom-rows-table {
  margin-bottom: 30px;
  color: var(--grey-font-color);
  .t-header {
    padding-bottom: 5px;
    border-bottom: 1px solid var(--grey-font-color);
    font-family: XBoldFont, sans-serif;
  }
}
/* End Custom Rows Table */
/* Multistep Breadcrumb */
.MuiSvgIcon-root {
  font-size: 1.85rem!important;
}
.MuiStepLabel-labelContainer {
  position: absolute;
  top: -40px;
}
.MuiPaper-root {
  padding-top: 40px!important;
  .MuiStep-root:nth-child(1) {
    padding-left: 0!important;
    .MuiStepLabel-root {
      align-items: flex-start;
      .MuiTypography-root {
        text-align: left;
      }
    }}}
.MuiPaper-root .MuiStep-root:nth-child(2) {
  .MuiStepConnector-root {
    left: calc(-99% + 41px) !important;
    right: calc(89% + -3px) !important;
  }
  .MuiStepLabel-root {
    align-items: flex-start;
    .MuiStepLabel-iconContainer {
      margin-left: 12%;
    }
    .MuiTypography-root {
      text-align: left;
      padding-left: 0;
    }
  }}
.MuiPaper-root .MuiStep-root:nth-child(3) {
  .MuiStepLabel-labelContainer{
    left: -15px; //IE Style
  }
  .MuiStepConnector-root {
    left: calc(-87% + 39px) !important;
    right: calc(40% + 33px) !important;
  }
  .MuiStepLabel-root {
    align-items: flex-end;
    .MuiStepLabel-iconContainer {
      margin-right: 38%;
      padding-left: 20px;
    }
    .MuiTypography-root {
      text-align: right;
      padding-right: 26%;
    }
  }}
.MuiPaper-root .MuiStep-root:nth-child(4) {
  padding-right: 0!important;
  .MuiStepLabel-labelContainer{
    left: -15px; //IE Style
  }
  .MuiStepConnector-root {
    left: calc(-34% + -10px) !important;
    right: calc(34% + 30px) !important;
  }
  .MuiStepLabel-root {
    align-items: flex-end;
    .MuiStepLabel-iconContainer {
      margin-right: 34%;
    }
    .MuiTypography-root {
      text-align: right;
      padding-right: 18%;
    }
  }}
.MuiPaper-root .MuiStep-root:nth-child(5) {
  padding-right: 0!important;
  .MuiStepLabel-labelContainer{
    left: 0; //IE Style
  }
  .MuiStepConnector-root {
    left: calc(-33% + 3px) !important;
    right: calc(1% + 34px) !important;
  }
  .MuiStepLabel-root {
    align-items: flex-end;
    .MuiTypography-root {
      text-align: right;
    }
  }}
.MuiStep-horizontal {
  padding: 0;
}
.MuiStep-completed {
  .linked-label {
    cursor: pointer;
    padding-bottom: 37px;
  }
}
.MuiStepper-root {
  padding-right: 0!important;
  padding-left: 0!important;
  .MuiStepLabel-active {
    color: var(--secondary-color)!important;
  }
}

.MuiStepConnector-alternativeLabel {
  .MuiStepConnector-line {
    border-radius: 15px;
    height: 5px;
    border: 0;
    background-color: $eless-light-gray;
  }
  &.MuiStepConnector-active, &.MuiStepConnector-completed {
    .MuiStepConnector-line {
      background-color: var(--secondary-color);
    }
  }
}
.MuiStepIcon-root {
  color: $eless-light-gray!important;
  .MuiStepIcon-text  {
    fill: #8898aa;
  }
  &.MuiStepIcon-active .MuiStepIcon-text {
    fill: #fff;
  }
  &.MuiStepIcon-active {
    color: var(--secondary-color)!important;
  }
  &.MuiStepIcon-completed {
    color: var(--secondary-color)!important;
    cursor: pointer;
  }
}
@media screen and (max-width: 849px){
  .MuiTypography-root {
    display: none!important;
  }
  .MuiPaper-root {
    padding-top:30px!important;
  }
}
@media screen and (max-width: 744px){
  .test-and-publish {
    display: none;
  }
}
/* End Multistep Breadcrumb */
/* Datepicker */
.custom-datepicker-wrapper {
  height: 48px;
  display: block;
  border-radius: 0.25rem;
}
.react-datepicker__input-container {
  font-size: 14px;
  input {
    color: var(--dark-font-color);
  }
}
.full-width-datepicker {
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }
}
.react-datepicker-wrapper {
  input {
    border: 1px solid #dee2e6;
    box-shadow: none!important;
    border-radius: 0.25rem;
    max-height: 46px;
    width: 100%;
    padding: 0.625rem 0.75rem;
  }
}

/* End Datepicker */
/* Crop Img Modal */
.cropimg-button-wrapper {
  text-align: center;
  margin-top: 35px;
}
.file-upload-button {
  line-height: inherit!important;
}
/* End Crop Img Modal */
/* file upload button */
.sm {
  .upload-button label {
    height: 31px;
    padding: 0 30px;
    line-height: 2;
  }
}
/* End file upload button */
/* Save Form Data */
.save-form-data-wrapper {
  z-index: 1500;
  background-color: var(--primary-color);
  color: #fff;
  text-align: center;
  transition-duration: 0.5s;
  &.display {
    transition-duration: 0.5s;
    height: 61px;
    padding: 15px 45px;
  }
  &.hide {
    height: 0;
    padding: 0 45px!important;
  }
}
@media screen and (max-width: 1097px) {
  .save-form-data-wrapper.display {
    height: 75px;
  }
}
@media screen and (max-width: 845px) {
  .save-form-data-wrapper.display {
    height: 91px;
    .primary-btn {
      margin-bottom: 0.5rem!important;
    }
    .btn-outline-eless {
      margin-left: 0;
    }
  }
}
@media screen and (max-width: 767px) {
  .save-form-data-wrapper.display {
    height: 150px;
    .text {
      margin-bottom: 5px;
    }
  }
}
/* End Save Form Data */
/* Create compaision file footer */
.create-comparision-file-footer {
  z-index: 1500;
  background-color: var(--primary-color);
  color: #fff;
  text-align: center;
  transition-duration: 0.5s;
  &.display {
    transition-duration: 0.5s;
    height: 61px;
    padding: 15px 45px;
  }
  &.hide {
    height: 0;
    padding: 0 45px!important;
  }
}
@media screen and (max-width: 899px) {
  .create-comparision-file-footer {
    &.display {
      height: 73px;
    }
  }
}
@media screen and (max-width: 845px) {
  .create-comparision-file-footer {
    &.display {
      height: 90px;
    }
    .create-button {
      width: 100%;
      margin-bottom: 5px;
    }
    .cancel-button {
      width: 100%;
      margin-left: 0;
    }
  }
}
@media screen and (max-width: 767px) {
  .create-comparision-file-footer {
    &.display {
      height: 125px;
    }
  }
}
/* End create compaision file footer */
/* Admin Navbar */
.notification-icon {
  font-size: 40px;
  padding-top: 10px;
}
/* End Admin Navbar */
/* Percent Value Input */
.percent-value-wrapper {
  input {
    text-align: right;
    padding-right: 30px;
  }
  input:disabled + span {
    color: $disabled-color!important;
  }
  span {
    position: absolute;
    top: 13px;
    right: 12px;
    font-size: 14px;
  }
};
/* End Percent Value Input */
/* More Options */
.more-options-wrapper {
  .more-options {
    font-size: 24px;
    color: var(--grey-font-color);
    cursor: pointer;
    padding: 0 15px;
  }
  .dropdown-options{
    border: 1px solid var(--primary-color);
    padding: 2px 15px;
    cursor: pointer;
    transition-duration: 0.5s;
    border-radius: 5px;
    div {
      margin: 0 auto;
    }
    &.left {
      left: -85px;
      top:-10px;
    }
    &:hover {
      background-color: var(--primary-color);
      color: #fff;
      transition-duration: 0.5s;
    }
  }

  .fade-enter {
    opacity: 0;
  }


  .fade-enter.fade-enter-active {
    opacity: 1;
    transition-duration: 0.5s;
  }

  .fade-leave {
    opacity: 0;
  }
}
/* End More Options */
/* Checkbox */
.custom-checkbox {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--dark-font-color)!important;
    background-color: var(--dark-font-color)!important;
  }
  .custom-control-input:disabled ~ .custom-control-label::after {
    background-color: #e9ecef!important;
    border: 1px solid #dee2e6!important;
    border-radius: 4px;
  }
}
.table {
  .custom-control {
    min-height: inherit;
  }
}
/* End Checkbox */
/* RadiosBoolean */
.custom-control-input:active ~ .custom-control-label::before {
  background-color: var(--dark-font-color);
  border-color: var(--dark-font-color);
}
.max-form-width {
  max-width: 420px;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--dark-font-color);
  border-color: var(--dark-font-color);
  &:active {
    background-color: var(--dark-font-color)!important;
  }
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: var(--dark-font-color);
  border-color: var(--dark-font-color);
  opacity: 0.3;
}
.custom-radio .custom-control-input:disabled:active ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.custom-radio .custom-control-input:disabled:checked:active ~ .custom-control-label::before {
  background-color: #8898aa!important;
}
.custom-control-label::after {
  left: -28px;
}
.custom-radio {
  .custom-control-label {
    padding-top: 1.5px;
    &:after {
      top: 4px;
      background-position: 55% center;
    }
  }
}
/* End RadiosBoolean */
/* Select */
select {
  font-family: RegularFont, sans-serif!important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
}
select option {
  font-family: RegularFont, sans-serif;
}
.custom-select-arrow:after {
  font-family: "Font Awesome 5 Free", serif;
  position: absolute;
  right: 15px;
  top: 16px;
  font-weight: 900;
  content: "\F078";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: var(--grey-font-color);
  font-size: 16px;
  pointer-events: none;
}
.line-graph-dropdown {
  font-family: SemiBoldFont, serif!important;
  border: 1px solid #dee2e6;
  &:focus {
    border-color: #dee2e6;
  }
}
/* End Select */
/* Badges */
.smaller-text-style {
  font-size: 11px!important;
  padding: 4px!important;
}
.badge {
  &.badge-dot {
    font-size: 16px;
    i  {
      margin-bottom: 1px;
    }
  }
}
.badge-pill {
  letter-spacing: 1.5px;
  font-size: 12px;
  padding: 4px 15px 4px 15px;
  font-family: SemiBoldFont, sans-serif!important;
  width: 160px;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
}
.badge-orange {
  background-color: #FFDABC;
  color: #fe821e;
}
.badge-green {
  background-color: #B0EED3;
  color: #1AAE6F;
}
.badge-red {
  background-color: #FDD1DA;
  color: #F80031;
}
.badge-gray {
  background-color: #D5D5D5;
  color: #8F7676;
}
.badge-blue {
  background-color: #aed9ff;
  color: #357df8;
}.badge-pink {
  background-color: #ffbaf4;
  color: #f848d4;
}
/* End Badges */
/* New Badges */
.new-badge-pill {
  border-radius: 5px;
  padding: 0 15px 2px 15px;
  font-family: XBoldFont, sans-serif!important;
  display: inline-block;
  text-align: center;
  font-size: 13px;
  &.badge-grey {
    background-color: $eless-light-gray;
    color: var(--dark-font-color);
  }
  &.badge-orange {
    background-color: var(--secondary-color);
    color: #fff;
  }
}
/* End New Badges */
/* Calculation settings Modal */
.calculation-settings-modal {
  .t-header {
    padding-bottom: 0;
    .row{
      margin-bottom: 0;
      padding-bottom: 5px;
      border-bottom: none;
    }
  }
  .t-body {
    .row {
      margin-bottom: 5px;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  .calculation-settings-table{
    .price {
      min-width: 120px;
    }
  }
}
/* END Calculation settings Modal */
/* Signature Button */
.signature-button {
  border: 1px solid #E9ECEF;
  color: var(--grey-font-color);
  border-radius: 4px;
  display: inline-block;
  padding: 8px 16px 4px;
  cursor: pointer;
  position: relative;
  min-width: 235px;
  width: 100%;
  height: 46px;
  transition-duration: 0.3s;
  &:hover {
    color: var(--dark-font-color);
  }
  .fa-signature {
    position: absolute;
    right: 31px;
    bottom: 10px;
  }
  .fa-pencil-alt {
    position: absolute;
    right: 9px;
    top: 13px;
  }
}
/* End Sign Button */
/* GreyLink Button */
div.disabled {
  .grey-link-button {
    opacity: 0.5;
    cursor: inherit;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
}
.grey-link-button {
  text-decoration: underline;
  color: var(--grey-font-color);
  cursor: pointer;
}
/* End GreyLink Button */
/* BlackLink Button */
.black-link-button {
  text-decoration: underline;
  color: var(--primary-color);
  cursor: pointer;
}
/* End BlackLink Button */
/* Settings Wheel Button Profile Page */
.settings-wheel-btn-profile {
  border: 2px solid var(--dark-font-color);
  border-radius: 4px;
  display: inline-block;
  padding: 2px 10px 4px 10px;
  cursor: pointer;
  transition-duration: 0.6s;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
  &:hover {
    background-color: var(--dark-font-color);
    color: #fff;
    transition-duration: 0.3s;
  }
  .fas {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
.download-icon-btn {
  border: 1px solid $eless-light-gray;
  color: var(--grey-font-color);
  border-radius: 4px;
  display: inline-block;
  padding: 8px 16px 4px;
  cursor: pointer;
  position: relative;
  .fas {
    position: absolute;
    right: 14px;
    top: 14px;
  }
}
/* End Settings Wheel Button Profile Page  */
/* Settings Wheel Button */
.settings-wheel-btn {
  border-radius: 4px;
  display: inline-block;
  border: 1px solid var(--dark-font-color);
  padding: 5px 6.5px 6px 6.5px;
  cursor: pointer;
  transition-duration: 0.6s;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
  transform: translateY(1px);
  &:hover {
    background-color: var(--dark-font-color);
    color: #fff;
    transition-duration: 0.3s;
  }
}
/* End Settings Wheel Button */
/* Download Button */
.download-btn {
  color: var(--dark-font-color);
  padding-right: 45px;
  padding-left: 20px;
  position: relative;
  .download-icon {
    position: absolute;
    right: 18px;
    top: 6px;
    font-size: 18px;
  }
}
.btn-right {
  text-align: right;
}
/* End Download Button */
/* Export Button */
.dopdown-button {
  .active {
    background-color: var(--secondary-color);
  }
}
/* End Export Button */
/* Table Style */
.table th {
  font-weight: normal!important;
  font-family: XBoldFont, sans-serif;
}
.page-item.active .page-link {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}
.empty-table-text{
  color: var(--grey-font-color);
};
.react-bootstrap-table-pagination .dataTables_length label {
  margin-bottom: 1.5rem;
  white-space: inherit;
}
@media screen and (min-width: 768px) {
  .table-title-wrapper {
    padding-left: 5px!important;
  }
}
.react-bootstrap-table {
  margin-bottom: 15px;
  th {
    color: var(--dark-font-color);
  }
}
.react-bootstrap-table .table {
  margin-bottom: 0;
}
.custom-table-link{
  color: var(--dark-font-color);
}
.custom-table-link:hover{
  text-decoration: none;
  cursor: pointer;
  color: var(--secondary-color);

}
.linked-col {
  cursor: pointer;
}
.selection-input-4 {
  cursor: pointer;
}
.custom-size-per-page {
  display: inline-block;
  width: inherit;
}
.thic-border {
  border-width: 2px;
}
.custom-border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 25px;
}
.pagination {
  justify-content: flex-end;
}
@media screen and (max-width: 691px){
  .pagination {
    justify-content: center!important;
  }
}
@media screen and (max-width: 575px){
  .react-bootstrap-table-pagination-list{
    display: none;
  }
}
.responsive-button-wrapper {
  display: inline-block;
}
@media screen and (max-width: 600px){
  .break-width-600.table-header-buttons {
    .responsive-button-wrapper {
      display: block;
      button, .dropdown-button-wrapper, .dropdown-options {
        width: 100% !important;
      }
      button {
        margin-bottom: 15px !important;
      }
    }

    .dataTables_filter {
      width: 100%;
      label {
        width: 100%;
        input {
          margin-left: 0;
          width: 100%;
        }
      }
      .search-label {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 700px){
  .break-width-700.table-header-buttons {
    .responsive-button-wrapper {
      display: block;
      button, .dropdown-button-wrapper, .dropdown-options {
        width: 100% !important;
      }
      button {
        margin-bottom: 15px !important;
      }
    }

    .dataTables_filter {
      width: 100%;
      label {
        width: 100%;
        input {
          margin-left: 0;
          width: 100%;
        }
      }
      .search-label {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 715px){
  .break-width-715.table-header-buttons {
    .responsive-button-wrapper {
      display: block;
      button, .dropdown-button-wrapper, .dropdown-options {
        width: 100% !important;
      }
      button {
        margin-bottom: 15px !important;
      }
    }

    .dataTables_filter {
      width: 100%;
      label {
        width: 100%;
        input {
          margin-left: 0;
          width: 100%;
        }
      }
      .search-label {
        display: none;
      }
    }
  }
}
.dataTables_filter {
  padding-right: 0;
  float: inherit;
  label {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 991px){
  .settings-wheel-btn.absolute-positioning {
    position: absolute;
    right: 15px;
    top: -45px;
  }
}
@media screen and (min-width: 846px) {
  .table-actions-wrapper {
    text-align: right;
  }
}
/* SizePerPage Select */
.size-per-page-select {
  height: 24px;
  line-height: 1;
  width: 42px;
}
/* End SizePerPage Select */
/* End Table Style */
/* Full Page Submit Button */
.full-page-submit {
  width: 100%;
  height: 46px;
}
/* End Full Page Submit Button */
/* Secondary Button */
.btn-outline-eless {
  border: 1px solid var(--secondary-color);
  background-color: #ffffff;
  color: var(--secondary-color);
  transform: none;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
  &:hover {
    background-color: #ffffff;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
  }
  &:disabled, &.disabled {
    opacity: 0.65;
    border: 1px solid var(--secondary-color)!important;
    background-color: #ffffff!important;
    color: var(--secondary-color)!important;
  }
}
.arrow-before {
  &:before {
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    content: "\f060";
    margin-right: auto;
    padding-right: 10px;
  }
}
.arrow-after {
  &:after {
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    content: "\f061";
    margin-left: auto;
    padding-left: 10px;
  }
}
.full-width-button {
  width: 100%;
  height: 50px;
}
/* End Secondary Button */
/* Dropdown Button */
.dropdown-button-wrapper {
  position: relative;
  display: inline-block;
  .dropdown-button {
    padding-right: 40px;
    color: var(--secondary-color);
    transition-delay: .4s;
    transform: none;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)!important;
    &.primary {
      color: #fff!important;
    }
    &.active {
      background-color: var(--secondary-color)!important;
      color: $light-font-color!important;
      border-color: var(--secondary-color)!important;
    }
    &:active {
      background-color: var(--secondary-color)!important;
      color: $light-font-color!important;
      border-color: var(--secondary-color)!important;
      transition-delay: 0s;
    }
    &:hover {
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }
    &:after {
      position: absolute;
      font-style: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: 'Font Awesome 5 Free';
      font-weight: 700;
      content: "\f078";
      margin-left: auto;
      font-size: 11px;
      top: 7px;
      right: 10px;
    }
    &:disabled {
      pointer-events: none!important;
      border-color: var(--secondary-color);
    }
  }
  .dropdown-options {
    font-family: SemiBoldFont, sans-serif;
    transition: all .5s ease-in-out;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    font-size: 0.875rem;
    position: absolute;
    z-index: 10;
    background-color: #fff;
    width: 135.5px;
    font-family: XBoldFont, sans-serif!important;
    top: 30px;
    transition-duration: 0.5s;
    overflow-y: hidden;
    &.primary {
      color: var(--primary-color);
      width: 233px;
      border: none;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)!important;
      border-radius: 5px;
      margin-top: 5px;
      div{
        text-align: left;
        padding-left: 15px;
        &.first-primary-option {
          border-bottom: 1px solid var(--primary-color);
        }
        &:hover {
          color: inherit;
          background-color: inherit;
          font-family: XBoldFont, sans-serif;
        }
      }
    }
    div {
      text-align: center;
      padding: 5px 0 5px 0;
      cursor: pointer;
      &:hover {
        color: $light-font-color;
        background-color: var(--secondary-color);
      }
    }
  }
}
/* Gray variant */
.gray-btn {
  border-color: var(--grey-font-color)!important;
  color: var(--grey-font-color)!important;
  &:disabled {
    border-color: var(--grey-font-color)!important;
    color: var(--grey-font-color)!important;
  }
  height: 40px;
  &:hover{
    border-color: var(--grey-font-color);
    color: var(--grey-font-color);
  }
}
/* End Gray variant */
/* Dropdown Animation */
.dropdown-button-wrapper {
  .fade-enter {
    height: 0;
  }

  .fade-enter.fade-enter-active.four-options {
    height: 128px;
  }

  .fade-leave.four-options {
    height: 128px;
  }

  .fade-enter.fade-enter-active.three-options {
    height: 96px;
  }

  .fade-leave.three-options {
    height: 96px;
  }

  .fade-enter.fade-enter-active {
    height: 64px;
  }

  .fade-leave {
    height: 64px;
  }

  .fade-enter.fade-enter-active.one-option {
    height: 32px;
  }

  .fade-leave.one-option {
    height: 32px;
  }

  .fade-enter.fade-enter-active.two-options {
    height: 64px;
  }

  .fade-leave.two-options {
    height: 64px;
  }

  .fade-leave.fade-leave-active { //Dieser Style muss als letztes kommen sonst buggt die Animation beim Schliessen
    height: 0;
  }
}
/* End Dropdown Button */
/* Submit Loading Animation Modal */
@keyframes spinner-border {
  to {transform: rotate(360deg);}
}

@keyframes spinner-grow {
  0% {
    -webkit-transform:scale(0);
    transform:scale(0)
  }
  50% {
    opacity:1
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border 1s linear infinite;
}

.spinner-grow {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite;
}
/* End Submit Loading Animation Modal */
/* Button loading Animation */
.button-loading {
  display: none;
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 24px;
}
/* End Button loading Animation */
/* Navabr User Logo Loading Animation */
.loading-user-logo span span {
  vertical-align: middle;
  border-style: none;
}
/* End Navabr User Logo Loading Animation */
/* Tooltip Button */
.huge-submit-hitbox {
  padding: 13px 30px;
  display: block;
}
/* End Tooltip Button */
/* Submit Button */
.primary-btn {
  overflow: hidden;
  position: relative;
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  color: $light-font-color;
  &:hover {
    border: 1px solid var(--secondary-color);
    background-color: var(--secondary-color);
    color: $light-font-color;
  }
}
.primary-btn {
  &:disabled {
    background-color: var(--secondary-color);
    color: #ffffff;
    border: 1px solid var(--secondary-color);
    &:hover {
      box-shadow: none;
      transform: inherit;
    }
  }
}
@media screen and (min-width: 692px) {
  .mobile-submit-style button {
    margin-left: 15px;
  }
}
/* End Submit Button
/* Dropzone */
.thin-dropzone p {
  padding: 8.5px 1rem!important;
}
.dropzone{
  display: block;
  p {
    padding: 5rem 1rem;
    border: 1px dashed $eless-light-gray;
    text-align: center;
    cursor: pointer;
    &:hover {
      border-color: gray;
    }
  }
}
.dropzone-skeleton {
  margin-bottom: 1rem;
}
.uploaded-dropzone-file-wrapper {
  margin-bottom: 15px;
  .avatar-wrapper {
    img {
      color: #fff;
      background-color: #adb5bd;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      border-radius: 0.375rem;
      height: 48px;
      width: 48px;
    }
  }
}
/* End Dropzone */

/* AUTO COMPLETE GET AG SERVICE */
/* auto- complete list design (GETAG) */
.autocomplete-items {
  position: absolute;
  border: 1px solid #f3f3f3;
  border-top: none;
  border-bottom: none;
  z-index: 99;
  top: 77%;
  left: 0;
  right: 0;
  border-radius: 20px!important;
  background-color: #fff;
}
.autocomplete-active, .autocomplete-items div:hover {
  background-color: var(--primary-color);
  color: #fff;
}
.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  border-bottom: 2px solid #f3f3f3;
}
@media screen and (max-width: 900px) and (min-width: 601px){
  .autocomplete-items {
    width: 90%!important;
  }
}
@media screen and (max-width: 600px){
  .autocomplete-items {
    width: 86%!important;
  }
}
.font-inherit {
  font: inherit!important;
}
.autocomplete-items div:last-child {
  border-bottom: none!important;
}
#street:disabled, #city.disabled{
  opacity: 0.6;
}
.autocomplete-items div:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.autocomplete-items div:last-child:hover {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: var(--primary-color);
  color: white;
  border: 2px solid var(--primary-color)!important;
}
/* END AUTO COMPLETE */
/* Collapse */
.collapse-header {
  cursor: pointer;
  background-color: $grey-box-background;
  color: var(--primary-color);
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 0.9px;
  font-family: XBoldFont, sans-serif!important;
  display: table;
  margin-bottom: 20px;
  .col {
    display: table-cell;
    vertical-align: middle;
  }
  .fas.fa-check-circle {
    font-size: 20px;
    color: #00C4AC;
    opacity: 0.4;
  }
  .complete {
    &.fas.fa-check-circle {
      opacity: 1!important;
    }
  }
  .fa-angle-down {
    font-size: 20px;
    color: var(--grey-font-color);
    &.open {
      -webkit-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -o-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg);
    }
  }
}
/* End Collapse */
/* Sidebar */
.navbar-vertical {
  .navbar-nav {
    padding-left: 10px;
    .nav-link > i{
      font-size: 18px;
      min-width: 32px;
      margin-top: -5px;
    }
    .nav-link[data-toggle="collapse"][aria-expanded="true"]:after {
      color: var(--primary-color);
    }
  }
}
.navbar-horizontal .navbar-brand img {
  max-height: 140px!important;
  height: inherit!important;
}
.navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand > img {
  max-height: 110px; //max height needed for internet explorer reasons
}
.scrollbar-inner h6 {
  font-size: 15px!important;
  letter-spacing: 0!important;
}
/* End Sidebar */
/* Admin Navbar */
.notifications-toggler {
  cursor: pointer;
  .ni, .fas {
    font-size: 22px;
  }
}
.new-events-count {
  background-color: var(--secondary-color);
  position: absolute;
  line-height: 0.9;
  border-radius: 12px;
  text-align: center;
  font-weight: bold;
  &.endless {
    height: 20px;
    width: 20px;
    bottom: -6px;
    right: 4px;
    padding-top: 3px;
    span {
      font-size: 10px;
      position: absolute;
      right: 1.5px;
      top: 5px;
    }
  }
  &.big {
    height: 20px;
    width: 20px;
    bottom: -6px;
    right: 4px;
    padding-top: 3px;
    span {
      font-size: 12px;
      position: absolute;
      right: 3.5px;
      top: 5px;
    }
  }
  &.low {
    height: 20px;
    width: 20px;
    bottom: -6px;
    right: 6px;
    padding-top: 2px;
    span {
      font-size: 12px;
      position: absolute;
      right: 6.5px;
      top: 5px;
    }
  }
}
/* Admin Navbar */
/* File Upload */
.custom-file-input, .custom-file-label {
  height: 46px!important;
  margin-bottom: 0;
}
.custom-file-input:focus ~ .alert-border:after{
  border-color: var(--error-color)!important;
}
.custom-file-label.alert-border::after{
  border-color: var(--error-color)!important;
}
.custom-file {
  height: 46px;
  border-radius: 4px;
}
.custom-file-label::after {
  content: "Suchen"!important;
  height: 42px;
}
.custom-file-input:focus ~ .custom-file-label, .custom-file-input:focus ~ .custom-file-label::after {
  border-color: var(--grey-font-color);
}
.file-upload-error-message {
  margin-top: -3px!important;
}
/* End File Upload */
/* Modal */
.modal-content-end-margin {
  margin-bottom: 2.5rem;
}
.modal-body .bg-secondary {
  background-color: var(--background-color)!important;
}
.btn-modal {
  padding: 11.5px 0 11.5px 0!important;
  height: inherit!important;
  width: 100%;
}
.close-button {
  font-size: 28px;
}
.modal-backdrop {
  z-index: 1000!important;
}
.wide-modal {
  max-width: 700px!important;
}
/* End Modal */
/* SearchSelect */
/**
 * Main wrapper
 */
.select-search {
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
  align-items: center;
  white-space: pre;
  -webkit-rtl-ordering: logical;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  border: 1px solid #dee2e6;
  box-shadow: none;
  border-radius: 0.25rem;
  &.has-focus {
    background-color: #fff;
    outline: 0;
    box-shadow: 0 3px 9px rgba(50, 50, 9, 0), 3px 4px 8px rgba(94, 114, 228, 0.1);
    border-color: var(--grey-font-color)!important;
  }
}
.dropdown-heading-value{
  color: var(--dark-font-color)!important;
}
.select-panel input[type="text"]:focus{
  border-color: var(--dark-font-color)!important;
}
.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
  position: relative;
  z-index: 1;
}
.select-search__value {
  input {
    color: var(--dark-font-color);
  }
}

.search-select-loading {
  position: relative;
  .fas {
    position: absolute;
    right: 16px;
    bottom: 13px;
    font-size: 18px;
    color: var(--grey-font-color);
  }
}
.select-search {
  .select-search__value::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f078";
    border-right: inherit !important;
    border-bottom: inherit !important;
    transform: inherit !important;
    position: absolute;
    right: 15px;
    top: 12px;
    font-size: 16px;
    color: var(--grey-font-color);
  }

  &.has-focus .select-search__value::after {
    content: "\f002" !important;
    top: 10px;
  }
}
.select-search__option.is-selected {
  background: #1e90ff;
  color: #fff;
  border: 2px solid var(--primary-color)!important;
}

/**
 * Input
 */
.select-search__input {
  display: block;
  height: 44px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
  background: #fff;
  border: 1px solid var(--grey-font-color);
  display: block;
}

.select-search__select ul{
  padding: 0;
  margin: 0;
  li {
    border: 1px solid #fff;
    button {
      padding-left: 16px!important;
      border: none;
    }
    &:hover {
      button {
        border: 2px solid var(--primary-color)!important;
        background-color: $standard-blue!important;
        color: #fff;
      }
    }
  }
}

.select-search__row {
  border-top: none!important;
}

.select-search__option {
  border: inherit!important;
  height: 24px!important;
}


/**
 * Options
 */
.select-search__options {
  list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option {
  display: block;
  height: 36px;
  width: 100%;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: #f4f7fa;
}
/**
 * Group
 */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

//.select-search:not(.select-search--multiple) .select-search__input:hover {
//  border-color: #2FCC8B;
//}


.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}
/* End SearchSelect */
/* Input Error */
.error {
  color: var(--error-color);
}
.argon-error-message {
  //width: 100%; //Die Fehlermeldung bei Dokumente hochladen im Auftrag war nicht sichtbar wegen dieses styles
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--error-color);
  position: absolute;
}
.alert-border {
  border: 1px solid var(--error-color)!important;
}
.alert-border-child {
  .multi-select .dropdown .dropdown-heading {
    border: 1px solid var(--error-color)!important;
  }
}
/* End Input Error */
/* Comparision File Footer */
.comparision-file-footer-btn {
  background-color: transparent;
  color: #fff;
}
/* End Comparision File Footer */
/* Footer */
#footer-main {
  width: 100%;
}
/* End Footer */
/* Progress Bar */
.progress {
  margin-bottom: 0;
}
.progress-wrapper {
  border: 1px solid $eless-light-gray;
  border-radius: 4px;
  padding: 1.5em
}
.progress-bar {
  width: 0;
}
.progress-status {
  text-transform: uppercase;
  font-size: 12px;
}
/* End Progress Bar */

/* Pages */
/* Guest Success Page */
.guest-success-card {
  max-width: 600px;
  margin: 0 auto;
}
/* End Guest Success Page */
/* Dashboard */
.dashboard-line-chart {
  height: 500px;
}
.line-graph-nav-link {
  background-color: var(--primary-color)!important;
  color: #fff!important;
}
.notification-description {
  color: var(--grey-font-color);
}
/* End Dashboard */
/* Single Contract */
.chat-wrapper {
  margin: 15px 20px;
}
.chat-actions {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
  .file-upload-wrapper {
    .fa-trash-alt {
      font-size: 18px;
      padding: 3px;
    }
  }
}
.chat-actions .logo-wrapper{
  border-radius: 30px;
}
.avatar {
  height: inherit;
}
.avatar-lg img{
  height: 56px;
  width: 56px;
}
.avatar-md img{
  height: 46px;
  width: 46px;
}
.avatar-sm img{
  height: 36px;
  width: 36px;
}
.timeline .avatar.rounded-circle img {
  border: 1px solid #e9ecef;
}
.rounded-circle, .avatar.rounded-circle img {
  background-color: #fff;
  max-width: inherit!important;
}
.timeline-block {
  font-size: 15px!important;
  &:last-child {
    margin-bottom: 2em;
  }
}
.timeline-upload {
  position: absolute;
  left: 0;
  width: 150px;
  height: 43px;
  opacity: 0;
  cursor: pointer;
}
.timeline-content {
  max-width: inherit!important;
  margin-left: 50px;
  padding-left: 10px;
  padding-bottom: .5rem;
  border-radius: 10px;
}
.timeline-attachment {
  margin-top: 8px;
  h5 {
    margin-bottom: 0;
  }
  .attachments {
    font-size: 0.8125rem;
    text-decoration: underline;
    a {
      margin-right: 4px;
      cursor: pointer;
    }
    a:not(:first-child) {
      margin-left: 4px;

    }
  }
}
.history-subject {
  font-family: SemiBoldFont, serif;
}
@media screen and (max-width: 617px){
  .chat-actions {
    .avatar, .message-input {
      margin-bottom: 15px;
    }
  }
  .responsive-line-break {
    width: 100%;
  }
  .chat-actions {
    .flex-fill {
      margin-right: 0!important;
    }
    .logo-wrapper {
      margin-bottom: 15px;
    }
    .file-upload-wrapper {
      width: 48%;
      .upload-button {
        width: 100%;
        label {
          width: 100%;
        }
      }
    }
    button {
      width: 48%;
      .btn-secondary {
        width: 100%;
      }
    }
  }
}

.upload-button .btn-secondary {
  cursor: pointer;
  &.disabled, &:disabled {
    background-color: #eeeeee;
    cursor: default;
    border-color: $disabled-color!important;
    color: $disabled-color!important;
    &:hover {
      transform: none;
    }
  }
}

.message-background {
  background-color: #fafafa;
  &.message-sent {
    background-color: #04113314!important; //background color for timeline messages has to have less opacity
    &:before {
      opacity: 0.1;
    }
  }
}


.uploaded-docs {
  position: absolute;
  font-size: 24px;
  right: 0;
  top: 10px;
  i.ni-single-copy-04 {
    margin-right: 10px;
    cursor: pointer;
  }
  i.ni-fat-remove {
    position: absolute;
    top: -5px;
    background-color: #e63647;
    border-radius: 15px;
    font-size: 20px;
    margin-left: -12px;
    display: none;
  }
}
.ni-single-copy-04:hover .ni-fat-remove {
  display: inline-block!important;
}
.contractdocuments-rows {
  padding-left: 20px;
  padding-right: 20px;
  .custom-border-bottom {
    margin-bottom: 0!important;
  }
  .row.file-row {
    padding-top: 20px;
    &:not(.has-changes){
      padding-bottom: 20px;
    }
  }
}
@media screen and (max-width: 715px) and (min-width: 467px) {
  .contractdocuments-rows {
    .file-row {
      .button-wrapper {
        margin-bottom: 15px;
      }
    }
  }
}
@media screen and (max-width: 466px){
  .contractdocuments-rows {
    .file-row {
      .col:first-child {
        margin-bottom: 10px;
      }
      .col {
        width: 100%!important;
        text-align: center;
        .button-wrapper {
          width: 100%;
          margin-bottom: 15px;
          div {
            text-align: center!important;
          }
        }
      }
    }
  }
}

/* Notifications Count */
.notifications-count {
  background-color: var(--primary-color);
  border-radius: 4px;
  font-size: 12px;
  padding: 0 2px;
  min-width: 17px;
  display: inline-block;
  text-align: center;
  color: #fff;
  opacity: 0.3;
  font-family: SemiBoldFont, serif;
}
/* End Notifications Count */

.custom-rows-table {
  .row {
    margin-bottom:20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $eless-light-gray;
  }
}
.dot-separator {
  vertical-align: middle;
  font-size: 4px;
  margin: 0 10px;
}
@media screen and (max-width: 767px){
  .col-max767-margin {
    margin-bottom: 15px;
  }
}
.disabled-row {
  opacity: 0.3;
}
.absolute-positioned-search {
  position: absolute;
  bottom: 0;
  right: 0;
}
.absolute-content {
  min-height: 50px;
}

/** Signle Contract Update **/
.info-section {
  margin: 25px;
  padding-bottom: 20px!important;
  font-size: 16px;
  h3 {
    text-transform: uppercase;
    font-size: 20px;
    color: var(--grey-font-color);
  }
  h4 {
    font-size: 16px;
    padding: 0;
    margin-bottom: 5px;
    color: var(--dark-font-color);
    text-transform: none;
    &.two-lines {
      margin-bottom: 29px;
    }
  }
  .left-info-col {
    //margin-right: 15px;
    //max-width: 200px;
  }
  .right-info-col {
    flex: 0 1 auto;
    span {
      margin-bottom: 5px;
      display: inline-block;
    }
  }
}
/** End Signle Contract Update **/
.contract-header, .consumption-point-header {
  .buttons-wrapper {
    text-align: right;
  }
}
@media screen and (max-width: 702px) {
  .contract-header {
    .column:first-child {
      margin-bottom: 15px!important;
    }
    .column {
      width: 100%;
      max-width: inherit!important;
      flex: inherit;
      text-align: center;
    }
  }
}
@media screen and (max-width: 767px) {
  .info-section {
    h4 {
      margin-bottom: 0;
    }
    .value {
      margin-bottom: 10px;
      .changes-detected {
        margin-left: 30px;
      }
    }
  }
}
@media screen and (max-width: 575px){
  .contract-header, .consumption-point-header {
    .list-middot > li {
      display: block;
      text-align: center;
      &:not(:last-child){
        margin-bottom: 10px;
      }
      &:after {
        content: ''!important;
        margin: 0!important;
      }
    }
    .buttons-wrapper {
      text-align: center;
    }
  }
}
@media screen and (max-width: 325px) {
  .chat-actions {
    .logo-wrapper {
      display: none;
    }
  }
}
/* End Single Contract */
/* Verification */
.verification-tabs {
  .tab-pane {
    min-height: 200px;
    padding: 1.5rem;
  }
}
/* End Verification */
/* Billing */
.grey-box {
  background-color: $grey-box-background;
  min-height: 115px;
  border-radius: 7px;
  .price-text {
    font-size: 24px;
  }
}
/* End Billing */
/* END Pages */
/* Loading Animation */
//<i className="fas spinner-fast" />
.spinner-parent {
  margin: 0 auto;
}
.spinner-fast>div {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  animation: spin 20s linear infinite;
}
.spinner-fast {
  width: 70px;
  height: 70px;
  border-top: 5px solid var(--primary-color);
  border-right: 5px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
@keyframes spin {
     100% {
       transform: rotate(360deg);
     }
 }
/* End Loading Animation */
/* LoadingAnimation 2 */
.loader{
  border-radius:4px;
  transition:opacity 400ms;
}

@keyframes pulse{
  0%{
    height:0%;
  }
  20%{
    background: var(--grey-font-color);
    height:100%;
  }
  50%{
    background: var(--primary-color);
  }
  80%{
    background: var(--grey-font-color);
    height:100%;
  }
  100%{
    height:0%;
  }
}

.loader > ul.loader-ul{
  list-style:none;
}

.loader li{
  width:4px;
  height:30px;
  border-radius:2px;
  position:absolute;
  left:50%;
  top: 40px;
  margin-left:-2px;
  transform-origin:2px 50px;
}

.loader li div{
  border-radius:2px;
  width:4px;
  background:rgba(255,255,255,0.5);
  position:absolute;
  bottom:0px;
  left:0px;
  animation:pulse 6s normal infinite;
}

ul.loader-ul>li:nth-of-type(1){
  transform:rotate(0deg);
  box-shadow:0 1px 0 rgba(255,255,255,0.1);
}

ul.loader-ul>li:nth-of-type(1)>div{
  animation-delay:100ms;
}

ul.loader-ul>li:nth-of-type(2){
  transform:rotate(30deg);
}

ul.loader-ul>li:nth-of-type(2)>div{
  animation-delay:200ms;
}


ul.loader-ul>li:nth-of-type(3){
  transform:rotate(60deg);
}

ul.loader-ul>li:nth-of-type(3)>div{
  animation-delay:300ms;
}


ul.loader-ul>li:nth-of-type(4){
  transform:rotate(90deg);
}

ul.loader-ul>li:nth-of-type(4)>div{
  animation-delay:400ms;
}


ul.loader-ul>li:nth-of-type(5){
  transform:rotate(120deg);
}

ul.loader-ul>li:nth-of-type(5)>div{
  animation-delay:500ms;
}


ul.loader-ul>li:nth-of-type(6){
  transform:rotate(150deg);
}

ul.loader-ul>li:nth-of-type(6)>div{
  animation-delay:600ms;
}


ul.loader-ul>li:nth-of-type(7){
  transform:rotate(180deg);
}

ul.loader-ul>li:nth-of-type(7)>div{
  animation-delay:700ms;
}


ul.loader-ul>li:nth-of-type(8){
  transform:rotate(210deg);
}

ul.loader-ul>li:nth-of-type(8)>div{
  animation-delay:800ms;
}


ul.loader-ul>li:nth-of-type(9){
  transform:rotate(240deg);
}
ul.loader-ul>li:nth-of-type(9)>div{
  animation-delay:900ms;
}


ul.loader-ul>li:nth-of-type(10){
  transform:rotate(270deg);
}
ul.loader-ul>li:nth-of-type(10)>div{
  animation-delay:1000ms;
}



ul.loader-ul>li:nth-of-type(11){
  transform:rotate(300deg);
}

ul.loader-ul>li:nth-of-type(11)>div{
  animation-delay:1100ms;
}


ul.loader-ul>li:nth-of-type(12){
  transform:rotate(330deg);
}

ul.loader-ul>li:nth-of-type(12)>div{
  animation-delay:1200ms;
}
.loader{
  width:320px;
  height: 100px;
}
/* End LoadingAnimation 2 */

/* OMGOMGMOMG */
.contract-guest-container {
  margin: 0 auto;
}